<template>
  <div class="pd10">
    <el-card class="custom-card">
      <div slot="header" class="clearfix">
        <span class="border-l relative pdL10">{{$route.meta.title}}</span>
      </div>
      <el-form ref="form" :rules="rules" :model="form" label-width="80px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="公司名称" class="custom-form-item">
              <el-input v-model="form.companyName" disabled placeholder="公司名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="用户名" prop="userName" class="custom-form-item">
              <el-input v-model="form.userName" placeholder="用户名"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="登录名" prop="userLogin" class="custom-form-item">
              <el-input v-model="form.userLogin" placeholder="登录名"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="手机号" prop="userPhone" class="custom-form-item">
              <el-input v-model="form.userPhone" type="number" placeholder="手机号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="所属角色" class="custom-form-item">
              <el-input v-model="form.roleName" disabled placeholder="所属角色"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="center">
          <el-button type="primary" @click="onSubmit('form')" :loading="loading" :disabled="loading">修 改
          </el-button>
        </div>
        <!--  </el-col>
       </el-row> -->
      </el-form>
    </el-card>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        loading: false,
        form: {
          userName: "",
          userLogin: "",
          userPhone: ''
        },
        rules: {
          userName: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          userLogin: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }],
          userPhone: [{
            required: true,
            message: '不可为空',
            trigger: 'blur'
          }, {
            validator: (rule, value, callback) => {
              if (!this.$util.isPoneAvailable(value)) {
                callback(new Error('请输入正确的手机号码!'));
              } else {
                callback();
              }
            },
            trigger: 'blur'
          }],
        }
      }
    },
    mounted() {
      this.seeUserOwn()
    },
    methods: {
      //修改提交
      onSubmit(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$confirm('确认要修改个人信息吗?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.modifyUserOwn()
            }).catch(() => {});

          } else {
            return false;
          }
        });
      },
      //修改
      modifyUserOwn() {
        this.loading = true
        this.$axios.Post(this.$api.modifyUserOwn, {
          "phone": this.form.userPhone,
          "userLogin": this.form.userLogin,
          "userName": this.form.userName
        }).then(() => {
          this.loading = false
          this.$message({
            type: 'success',
            message: "修改成功"
          });
          this.$store.dispatch('LogOut').then(() => {
            location.reload() // 为了重新实例化vue-router对象 避免bug
          })
        }).catch((err) => {
          this.loading = false
          if (err.errMsg) {
            this.$message({
              type: 'error',
              message: err.errMsg
            });
          }
        })
      },
      //获取
      seeUserOwn() {
        this.$axios.Get(this.$api.seeUserOwn, {}).then((res) => {
          this.form = res.data
        })
      }
    }
  }
</script>

<style scoped lang="less">
</style>
